import React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import VisuelContent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import { getImage } from '../../../Utils/Utils';

const PageFooter = ({ data, pageContext }) => {
  const { locale } = pageContext;
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.footerData?.edges[0]?.node;

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={pageData?.title}
        description="Description de la Home Page de Colas"
      />
      <div className="page_template">

        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              currentPage: { title: pageData?.title, url: locale + pageData?.path?.alias }
            }}
          />
          <TitlePage
            color="color_dark_bleu"
            title={pageData?.title}
            description=""
          />
        </div>
        {pageData?.relationships?.field_image?.drupal_internal__mid && <VisuelContent visuel={getImage(imagesArray, pageData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp} />}
        <div className="wrapper_page_xs" dangerouslySetInnerHTML={{ __html: pageData?.body?.processed }}>

        </div>
      </div>
    </Layout>
  );
};

PageFooter.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export const query = graphql`
  query pageFooterTemplateQuery(
    $locale: String!
    $slug: String!
    $imageID: [Int!]
  ) {
    footerData: allNodePage(
      filter: {
        langcode: {eq: $locale},
        path: {alias: {eq: $slug}}
      }
    ) {
      edges {
        node {
          title
          status
          field_type
          langcode
          body {
            processed
          }
          path {
            alias
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_mobile_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"},
        drupal_internal__mid: {in: $imageID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }
  }
`;

export default PageFooter;
